// src/App.js
import React from 'react';
import MainContent from "./MainContent";
import Header from "./app/Header";
import Footer from "./app/Footer";

function App() {
    return (
        <div className="App">
            <Header/>
            <MainContent/>
            <Footer/>
        </div>
    );
}

export default App;
